import React from "react";

function SkillsPage(props) {
  return (
    <>
      <header class="pageHeader">
        <div class="container">
          <h1>Skills &amp; Experience</h1>
        </div>
      </header>
      <div className="container">Coming soon...</div>
    </>
  );
}

export default SkillsPage;
