import React, { useEffect } from "react";

import DownloadIcon from "../img/download.png";

function ContactPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header className="pageHeader">
        <div className="container">
          <h1>Resume</h1>
          <h3 className="downloadResume">
            <a
              href="/Matthieu Disna Resume.pdf"
              target="_blank"
              rel="noreferrer"
            >
              <img src={DownloadIcon} alt="Download" /> Download PDF
            </a>
          </h3>
        </div>
      </header>
      <div className="container resume">
        <h3>Summary</h3>
        <p>
          Software developer and technical support engineer with several years
          of experience supporting web and SaaS applications. A motivated
          self-starter with a passion for learning new technologies.
        </p>
        <h3>Qualifications</h3>
        <p>
          <strong>Development</strong>
        </p>
        <ul>
          <li>
            Proficient in backend development using PHP, NodeJS, and MySQL
          </li>
          <li>Proficient in frontend development using Angular, ReactJS</li>
          <li>Experience with MongoDB, GraphQL</li>
        </ul>
        <p>
          <strong>Support</strong>
        </p>
        <ul>
          <li>5+ years of experience in providing technical support</li>
          <li>5+ years of freelance experience, working remotely</li>
          <li>Excellent command of written and spoken English</li>
        </ul>
        <h3>Experience</h3>
        <p>
          <strong>
            Senior Web Developer <span className="alignRight">2012-2018</span>
          </strong>
          <br />
          <em>
            Webright Services, LLC <span className="alignRight">Remote</span>
          </em>
        </p>
        <ul>
          <li>
            Converted provided website designs into mobile-friendly websites for
            the Wild Apricot CMS
          </li>
          <li>
            Provided technical support and wrote documentation for Wild Apricot
            customizations
          </li>
          <li>Developed custom solutions using PHP and MySQL</li>
        </ul>
        <p>
          <strong>
            Technical Support Analyst{" "}
            <span className="alignRight">2009-2012</span>
          </strong>
          <br />
          <em>
            Wild Apricot (Acquired by Personify in 2017){" "}
            <span className="alignRight">Toronto, Ontario</span>
          </em>
        </p>
        <ul>
          <li>
            Responded to technical support requests via email and by phone
          </li>
          <li>
            Drafted and updated online documentations, as well as recorded
            support videos
          </li>
          <li>Assisted clients with CSS and JavaScript issues</li>
        </ul>
        <p>
          <strong>
            Network Support Engineer / Developer{" "}
            <span className="alignRight">2007-2008</span>
          </strong>
          <br />
          <em>
            Superclick, Inc. (Acquired by AT&amp;T in 2011){" "}
            <span className="alignRight">Montr&eacute;al, Qu&eacute;bec</span>
          </em>
        </p>
        <ul>
          <li>Provided phone technical support to guests at various hotels</li>
          <li>
            Correspond with and walk hotel staff support through maintaining and
            troubleshooting network hardware
          </li>
          <li>
            Added additional functionality to in-house web application using PHP
          </li>
        </ul>
        <h3>Education</h3>
        <p>
          <strong>
            Humber College <span className="alignRight">2018-2021</span>
          </strong>
          <br />
          <em>Toronto, Ontario</em>
          <br />
          Advanced Diploma, Web Design and Interactive Media
        </p>
      </div>
      <h3 className="downloadResume">
        <a href="/Matthieu Disna Resume.pdf" target="_blank" rel="noreferrer">
          <img src={DownloadIcon} alt="Download" /> Download PDF
        </a>
      </h3>
    </>
  );
}

export default ContactPage;
