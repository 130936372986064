import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Analytics from "react-router-ga";

import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectPage from "./pages/ProjectPage";
import SkillsPage from "./pages/SkillsPage";
import ResumePage from "./pages/ResumePage";

import Nav from "./components/Nav";
import "./css/styles.css";
import Footer from "./components/Footer";

function App() {
  const trackingId = "UA-194985710-1";

  return (
    <Router>
      <Analytics id={trackingId}>
        <Nav />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/projects" component={ProjectsPage} />
        <Route exact path="/project/:projectName" component={ProjectPage} />
        <Route exact path="/skills" component={SkillsPage} />
        <Route exact path="/resume" component={ResumePage} />
        <Footer />
      </Analytics>
    </Router>
  );
}

export default App;
