import React from "react";

import LinkedIn from "../img/linkedin.png";
import Medium from "../img/medium.png";
import GitHub from "../img/github.png";

function Footer(props) {
  return (
    <footer>
      <div className="container">
        <h2>Let's make great things!</h2>
        <p>
          I love challenges and interesting projects. Have something in mind
          that fits the bill? I'd love to hear about it!
        </p>
        <a
          className="emailAddress"
          href="mailto:matt@mdisna.dev"
          alt="Email Link"
        >
          matt@mdisna.dev
        </a>
        <span className="sm-icons">
          <a
            href="https://github.com/mDisna"
            target="_blank"
            title="GitHub"
            rel="noreferrer"
          >
            <img
              src={GitHub}
              className="socialMediaIcon"
              alt="GitHub"
              title="GitHub Profile"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/mdisna"
            target="_blank"
            title="LinkedIn"
            rel="noreferrer"
          >
            <img
              src={LinkedIn}
              className="socialMediaIcon"
              alt="LinkedIn"
              title="LinkedIn Profile"
            />
          </a>
          <a
            href="https://medium.com/@mdisna"
            target="_blank"
            title="Medium"
            rel="noreferrer"
          >
            <img
              src={Medium}
              className="socialMediaIcon"
              alt="Medium"
              title="Blog on Medium"
            />
          </a>
          <div className="clear"></div>
        </span>
        <div className="clear"></div>
      </div>
    </footer>
  );
}

export default Footer;
