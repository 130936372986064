import React from "react";
import { Link } from "react-router-dom";

function Nav(props) {
  return (
    <nav>
      <div className="container">
        <div className="logo">
          <Link to="/" title="mdDisna.DEV">
            <img src="/img/site-logo.gif" alt="mDisna.DEV logo" />
          </Link>
        </div>
        <ul>
          <li>
            <Link to="/" title="Home Page">
              Home
            </Link>
          </li>
          <li>
            <Link to="/projects" title="Projects Page">
              Projects
            </Link>
            <ul>
              <li>
                <Link
                  to="/project/capstone"
                  title="About CreatorConnect Capstone Project"
                >
                  CreatorConnect
                  <p className="tinyText">
                    React, Node, MongoDB, GraphQL, Google oAuth
                  </p>
                </Link>
              </li>
              <li>
                <Link
                  to="/project/gaylesta"
                  title="About Gaylesta Directory Project"
                >
                  Gaylesta Directory
                  <p className="tinyText">Angular, PHP, MySQL</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/project/apricotmaps"
                  title="About ApricotMaps SaaS Project"
                >
                  ApricotMaps SaaS
                  <p className="tinyText">Angular, PHP, MySQL</p>
                </Link>
              </li>
              <li>
                <Link
                  to="/project/liberion"
                  title="About Liberion Shopify Project"
                >
                  Liberion Apparel
                  <p className="tinyText">Shipify Liquid, Bootstrap</p>
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/resume">Resume</Link>
          </li>
        </ul>
        <div className="clear"></div>
      </div>
    </nav>
  );
}

export default Nav;
