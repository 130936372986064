import React from "react";
import ReactTypingEffect from "react-typing-effect";

import { useWindowDimensions, useScrollPosition } from "../hooks/hooks";

function Hero(props) {
  const { height } = useWindowDimensions();
  const scrollPosition = useScrollPosition();

  const headlines = [
    "Full-Stack Developer",
    "JavaScript Developer",
    "PHP Developer",
    "Your Next Developer",
  ];

  return (
    <div className="hero" id="homeSection" style={{ height: height }}>
      <div
        className="container heroCallout"
        style={{ marginTop: scrollPosition }}
      >
        <h1>Matthieu Disna</h1>
        <ReactTypingEffect
          text={headlines}
          className="headline"
          eraseSpeed={100}
          eraseDelay={300}
          typingDelay={250}
        />
      </div>
    </div>
  );
}

export default Hero;
