import React from "react";
import { Link } from "react-router-dom";

function Featured(props) {
  return (
    <div className="container" id="FeaturedSection">
      <h3>Featured Project - Capstone</h3>
      <div className="featured">
        <div className="img">
          <img
            src="/img/projects/capstone.png"
            className="featuredImg"
            alt="Screenshots of CreatorConnect Capstone project"
          />
        </div>
        <div className="info">
          <h4>CreatorConnect.io</h4>
          <p className="tools">
            <span>NodeJS</span> <span>ReactJS</span> <span>MongoDB</span>{" "}
            <span>GraphQL</span> <span>Semantic UI</span> <span>oAuth</span>{" "}
            <span>Google API</span>
          </p>
          <p>
            This was a three month project in which I taught myself a number of
            number of new tools to build a CRUD application with SSO, user
            authentication, form validation, and in-site messaging.
          </p>
          <p>
            <Link
              to="/project/capstone"
              className="learnMore"
              title="Learn More"
            >
              Learn more
            </Link>
          </p>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
}

export default Featured;
