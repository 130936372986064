import React, { useEffect } from "react";
import Hero from "../components/Hero";
import Featured from "../components/Featured";
import Profile from "../components/Profile";
import Projects from "../components/Projects";
import Stacks from "../components/Stacks";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let stacks = [
    {
      label: "AngularJS",
      img: "angularJS.png",
      bg: "#E38B1E",
    },
    {
      label: "ReactJS & React Native",
      img: "react.png",
      bg: "#FAD005",
    },
    {
      label: "ES6 JavaScript",
      img: "es6.png",
      bg: "#F562EE",
    },
    {
      label: "NodeJS",
      img: "node.png",

      bg: "#F5E162",
    },
    {
      label: "Git & GitHub",
      img: "git.png",
      bg: "#EBC45E",
    },
    {
      label: "SASS Stylesheets",
      img: "sass.png",
      bg: "#F0DB05",
    },

    {
      label: "PHP",
      img: "php.png",
      bg: "#05F0EE",
    },
    {
      label: "MySQL",
      img: "mysql.png",
      bg: "#E3941E",
    },
    {
      label: "Shopify Liquid",
      img: "shopify.png",
      bg: "#F5628A",
    },
    {
      label: "GraphQL",
      img: "graphql.png",
      bg: "#E38B1E",
    },
  ];

  return (
    <>
      <Hero />
      <Profile />
      <Stacks stacks={stacks} />
      <Featured />
      <Projects />
    </>
  );
}

export default HomePage;
