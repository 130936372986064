import React, { useEffect } from "react";
import Featured from "../components/Featured";
import Projects from "../components/Projects";

function ProjectsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header className="pageHeader">
        <div className="container">
          <h1>Projects</h1>
        </div>
      </header>
      <Featured />
      <Projects className="solidBg" />
    </>
  );
}

export default ProjectsPage;
