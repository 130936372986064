import React, { useEffect } from "react";
import {
  Capstone,
  Liberion,
  ApricotMaps,
  Gaylesta,
} from "../components/ProjectDetails";

function ProjectsPage(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const projectName = props.match.params.projectName;

  let headerMarkup;
  let componentMarkup;

  switch (projectName) {
    case "capstone":
      headerMarkup = (
        <>
          <h1>CreatorConnect.io Project</h1>
          <p className="tools">
            <span>NodeJS</span> <span>ReactJS</span> <span>MongoDB</span>{" "}
            <span>GraphQL</span> <span>Semantic UI</span> <span>oAuth</span>{" "}
            <span>Google API</span>
          </p>
        </>
      );
      componentMarkup = <Capstone />;
      break;
    case "liberion":
      headerMarkup = (
        <>
          <h1>Liberion Shopify Project</h1>
          <p className="tools">
            <span>Shopify</span> <span>Liquid</span> <span>Bootstrap</span>
          </p>
        </>
      );
      componentMarkup = <Liberion />;
      break;
    case "gaylesta":
      headerMarkup = (
        <>
          <h1>Gaylesta Directory Project</h1>
          <p className="tools">
            <span>PHP</span> <span>MySQL</span> <span>Angular</span>{" "}
            <span>jQuery</span> <span>Bootstrap</span> <span>API</span>
          </p>
        </>
      );
      componentMarkup = <Gaylesta />;
      break;
    case "apricotmaps":
      headerMarkup = (
        <>
          <h1>ApricotMaps Saas Project</h1>
          <p className="tools">
            <span>PHP</span> <span>MySQL</span> <span>Angular</span>{" "}
            <span>jQuery</span> <span>Bootstrap</span> <span>API</span>
          </p>
        </>
      );
      componentMarkup = <ApricotMaps />;
      break;
    default:
      props.history.push("/projects");
      break;
  }

  return (
    <>
      <header className="pageHeader">
        <div className="container">{headerMarkup}</div>
      </header>
      <div className="container">{componentMarkup}</div>
    </>
  );
}

export default ProjectsPage;
