import React from "react";
import { useWindowDimensions } from "../hooks/hooks";

function Stacks({ stacks }) {
  const { width } = useWindowDimensions();

  // set the hieght so it matches the width from css
  const stackHeight = width / 5;
  return (
    <div id="experienceSection">
      {stacks.map((stack, index) => {
        return (
          <div
            className="stack"
            style={{ height: stackHeight, backgroundColor: stack.bg }}
            key={index}
            title={stack.label}
          >
            <img src={`/badges/${stack.img}`} alt={`${stack.label}`} />
          </div>
        );
      })}
      <br style={{ clear: "right" }} />
    </div>
  );
}

export default Stacks;
