import React from "react";

export function Capstone() {
  return (
    <>
      <div className="summary">
        <div className="videoWrapper">
          <iframe
            id="ytplayer"
            type="text/html"
            width="720"
            height="405"
            src="https://www.youtube.com/embed/2ncHPIZYt4s?autoplay=1&controls=0&modestbranding=1&vq=hd1080"
            frameBorder="0"
            allowFullScreen
            title="Capstone Project: CreatorConnect.io"
          />
        </div>
        <div className="synopses">
          <h3 className="sectionTitle">Synopses</h3>
          <p>
            CreatorConnect.io is a website catering to YouTube content creators.
            For creators looking to grow their channels, doing a collaboration
            with other YouTube channels will allow a new audience to see their
            content which could lead to new conversions (channel subscriptions).
          </p>
          <p>
            CreatorConnect.io helps to facilitate this by allowing creators to
            log in with their Google YouTube account and post collaboration
            requests. These requests can have restrictions such as only allowing
            channels with a set minimum of subscribers to apply. By tapping into
            the YouTube Data API using Google oAuth, CreatorConnect can ensure
            only the right creators can apply for a particular collaboration.
          </p>
          <p className="visitWebsite">
            <a
              href="https://creatorconnect.io"
              target="_blank"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </p>
        </div>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Features</h3>
        <ul className="features">
          <li>User authentication</li>
          <li>Form Validation</li>
          <li>Database CRUD</li>
          <li>Private Messaging</li>
        </ul>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Technologies</h3>
        <ul>
          <li>MERNG: MongoDB, Express, React, Node, GraphQL</li>
          <li>MongoDB Atlas</li>
          <li>Google oAuth API</li>
          <li>YouTube Data API</li>
          <li>Heroku Hosting</li>
        </ul>
      </div>
    </>
  );
}

export function Gaylesta() {
  return (
    <>
      <div className="summary">
        <img src="/img//projects/gaylesta.gif" alt="" width="100%" />
        <div className="synopses">
          <h3 className="sectionTitle">Synopses</h3>
          <p>
            Gaylesta is a psychotherapist association located San Francisco, CA.
            Gaylesta was seeking an interactive member directory that would
            allow users to find a therapist based on location in addition to
            other search criteria. Clicking on a result will take a user to a
            custom profile page with information pulled from the membership
            platform.
          </p>
          <p>
            The directory incudes an admin dashboard where administrators can
            review incorrect addresses that could not be geocoded, update the
            membership groups that are included, change the field mapping for
            their membership platform, manually update the automatically
            assigned profile URLs, and much more.
          </p>
          <p className="visitWebsite">
            <a
              href="https://directory.gaylesta.org/find-a-therapist/"
              target="_blank"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </p>
        </div>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Features</h3>
        <ul>
          <li>User Authentication</li>
          <li>Form Validation</li>
          <li>Database CRUD</li>
          <li>htaccess Redirection</li>
          <li>CRON Automation</li>
        </ul>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Technologies</h3>
        <ul>
          <li>LAMP: Linux, Angular, MySQL, PHP</li>
          <li>CRON</li>
          <li>oAuth API</li>
          <li>Google Maps API</li>
        </ul>
      </div>
    </>
  );
}

export function ApricotMaps() {
  return (
    <>
      <div className="summary">
        <img src="/img//projects/apricotmaps.gif" alt="" width="100%" />
        <div className="synopses">
          <h3 className="sectionTitle">Synopses</h3>
          <p>
            ApricotMaps is my foray into building a Software-as-a-Service. After
            building a number of custom directories for for users of the Wild
            Apricot SaaS platform, I decided to try and automate the development
            process. The result was a self-service platform, faster setups, an
            easier ability to update the codebase for multiple clients, and a
            lower initial investment for clients while providing a steady
            recurring income.
          </p>
          <p>
            ApricotMaps is a self-sufficient service that automatically
            generates customizable membership directories for users of the Wild
            Apricot membership platform. The itself also uses the WIld Apricot
            platform to manage clients and accept payments. A custom PHP
            dashboard and backend then taps into Wild Apricot via oAuth to
            determine who is a current and paid client for ApricotMaps. Then,
            using the API keys that clients provide, as well as the custom field
            mapping clients set up within the dashboard. the platform then
            geocodes the addresses for their membership database using the
            Google Maps API and generates a custom directory widget using
            Angular.
          </p>
          <p>
            <strong>Live widget examples:</strong>{" "}
            <a
              href="https://www.staylocal.org/find"
              target="_blank"
              rel="noreferrer"
              title="New Orleans' Independent Business Alliance"
            >
              StaylLocal
            </a>{" "}
            |{" "}
            <a
              href="https://www.napochicago.com/zipcodesearch"
              target="_blank"
              rel="noreferrer"
              title="National Association of Productivity and Organizing Professionals"
            >
              NAPO Chicago
            </a>{" "}
            |{" "}
            <a
              href="https://secure.aadmm.com/find-a-dmm/"
              target="_blank"
              rel="noreferrer"
              title="American Association of Daily Money Managers"
            >
              AADMM
            </a>{" "}
          </p>
          <p className="visitWebsite">
            <a
              href="https://liberion-apparel.myshopify.com/"
              target="_blank"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </p>
        </div>
        <div className="clear"></div>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Features</h3>
        <ul>
          <li>Autonomous Service</li>
          <li>Highly Customizable</li>
          <li>User Authentication</li>
          <li>Database CRUD</li>
          <li>htaccess Redirection</li>
          <li>CRON Automation</li>
        </ul>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Technologies</h3>
        <ul>
          <li>API</li>
          <li>oAuth</li>
          <li>PHP</li>
          <li>MySQL</li>
          <li>Angular</li>
          <li>jQuery</li>
          <li>Bootstrap</li>
        </ul>
      </div>
    </>
  );
}

export function Liberion() {
  return (
    <>
      <div className="summary">
        <img src="/img//projects/liberion.gif" alt="" width="100%" />
        <div className="synopses">
          <h3 className="sectionTitle">Synopses</h3>
          <p>
            Liberion is a test theme development site for Shopify. It features a
            custom built theme using the Liquid template language.
          </p>
          <p>
            Shopify requires all development shops to be password protected. You
            can view this development website by entering in password{" "}
            <span className="password">liberion</span>.
          </p>
          <p className="visitWebsite">
            <a
              href="https://liberion-apparel.myshopify.com/"
              target="_blank"
              rel="noreferrer"
            >
              Visit Website
            </a>
          </p>
        </div>
        <div className="clear"></div>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Features</h3>
        <ul>
          <li>New Custom Theme</li>
          <li>Custom Slider Widget</li>
        </ul>
      </div>
      <div className="additionalInfo">
        <h3 className="sectionTitle">Technologies</h3>
        <ul>
          <li>Liquid</li>
          <li>jQuery</li>
          <li>Bootstrap</li>
        </ul>
      </div>
    </>
  );
}
