import React from "react";
import Avatar from "../img/avatar.png";

function Profile(props) {
  return (
    <div className="container profile" id="profileSection">
      <div className="profileHeader">
        <div className="avatarCol">
          <img src={Avatar} className="avatar" alt="avatar" />
        </div>
        <div className="bio">
          <h3>Interactive Media &amp; Web Developer</h3>
          <p>
            A Frontend &amp; Backend developer with a love of learning new
            technology stacks. As graduate of the{" "}
            <a
              href="https://mediaarts.humber.ca/programs/web-design-interactive-media.html"
              rel="noreferrer"
              target="_blank"
            >
              Web Design &amp; Interactive Media
            </a>{" "}
            program at Humber College (2021), Matthieu also has a working
            knowledge of a wide range of media tools for print, motion graphics,
            and video.
          </p>
        </div>
      </div>
      <br style={{ clear: "both" }} />
    </div>
  );
}

export default Profile;
