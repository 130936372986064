import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

function Projects(props) {
  let passedClass = props.class;
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className={`container projects ${passedClass}`} id="projectsSection">
      <h3>Selected Works</h3>
      <div className="project ">
        <div className="column projectCol img">
          <Carousel
            arrows
            containerClass="container"
            infinite
            responsive={responsive}
            slidesToSlide={1}
            swipeable
          >
            <img
              src="/img/projects/gaylesta-1.jpg"
              className="slideImg"
              alt="Gaylesta Screenshot"
            />
            <img
              src="/img/projects/gaylesta-2.jpg"
              className="slideImg"
              alt="Gaylesta Screenshot"
            />
            <img
              src="/img/projects/gaylesta-3.jpg"
              className="slideImg"
              alt="Gaylesta Screenshot"
            />
          </Carousel>
        </div>
        <div className="column projectCol info">
          <div>
            <h4>Gaylesta Therapist Directory</h4>
            <p className="tools">
              <span>PHP</span> <span>MySQL</span> <span>Angular</span>{" "}
              <span>jQuery</span> <span>Bootstrap</span> <span>API</span>
            </p>
            <p>
              There were three parts to this project: a searchable member
              directory, custom member profiles, and an administration
              dashboard.
            </p>
            <p>
              The backend was built in PHP, and uses htaccess wildcards for the
              profile URLs. The directory uses Google's Geocoding API to perform
              proximity searches.
            </p>
            <p>
              <Link
                to="/project/gaylesta"
                className="learnMore"
                title="Learn More"
              >
                Learn more
              </Link>
            </p>
          </div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="project">
        <div className="column projectCol img">
          <Carousel
            arrows
            containerClass="container"
            infinite
            responsive={responsive}
            slidesToSlide={1}
            swipeable
          >
            <img
              src="/img/projects/apricotmaps-1.jpg"
              className="slideImg"
              alt="ApricotMaps Screenshot"
            />
            <img
              src="/img/projects/apricotmaps-2.jpg"
              className="slideImg"
              alt="ApricotMaps Screenshot"
            />
            <img
              src="/img/projects/apricotmaps-3.jpg"
              className="slideImg"
              alt="ApricotMaps Screenshot"
            />
          </Carousel>
        </div>
        <div className="column projectCol info">
          <div>
            <h4>ApricotMaps SaaS Platform</h4>
            <p className="tools">
              <span>PHP</span> <span>MySQL</span> <span>Angular</span>{" "}
              <span>jQuery</span> <span>Bootstrap</span> <span>API</span>
            </p>
            <p>
              ApricotMaps is a self-sufficient service that automatically
              generates membership directories for users of the Wild Apricot
              membership platform. Users can sign up and setup up a directory
              that taps into their membership software with a few easy clicks.
            </p>
            <p>
              <Link
                to="/project/apricotmaps"
                className="learnMore"
                title="Learn More"
              >
                Learn more
              </Link>
            </p>
          </div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="project">
        <div className="column projectCol img">
          <Carousel
            arrows
            containerClass="container"
            infinite
            responsive={responsive}
            slidesToSlide={1}
            swipeable
          >
            <img
              src="/img/projects/liberion-1.jpg"
              className="slideImg"
              alt="Liberion Screenshot"
            />
            <img
              src="/img/projects/liberion-2.jpg"
              className="slideImg"
              alt="Liberion Screenshot"
            />
            <img
              src="/img/projects/liberion-3.jpg"
              className="slideImg"
              alt="Liberion Screenshot"
            />
          </Carousel>
        </div>
        <div className="column projectCol info">
          <div>
            <h4>Liberion Apparel</h4>
            <p className="tools">
              <span>Shopify</span> <span>Liquid</span> <span>Bootstrap</span>
            </p>
            <p>
              This is a Shopify development test site that has been fully
              skinned and populated with dummy products.
            </p>
            <p>
              <Link
                to="/project/liberion"
                className="learnMore"
                title="Learn More"
              >
                Learn more
              </Link>
            </p>
          </div>
        </div>
        <div className="clear"></div>
      </div>
    </div>
  );
}

export default Projects;
